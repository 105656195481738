import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getLastestVersion, setFcmToken } from '../apis/appApi';
import { deviceOs } from '../modules/platformCheck';
import {
  appStatusState,
  forceState,
  indexPopUpState,
  spinnerState,
  updatePopUpState,
} from '../states/globalAlert';
import { userInfoState } from '../states/UserInfoState';
import { deleteCookie, getCookie, setCookieApp, setCookieMonth } from '../utils/cookie';
import { ACCESS_TOKEN } from './link';

import usePostMessageReceiver from 'hooks/usePostMessageReceiver';

import TimeoutModal from '@components/Atoms/Modal/timeout/TimeoutModal';
import usePushAlarmRedirect from '@hooks/usePushAlarmRedirect';
import ForceRouter from '@routes/ForceRouter';
import Router from '@routes/Router';
import ToastModal from 'components/Atoms/Modal/toast/ToastModal';
import SplashModal from 'components/Atoms/splashModal/SplashModal';
import SplashModalContainer from 'components/Atoms/splashModal/SplashModalContainer';
import ForceUpdateModal from 'components/modal/forceUpdateModal/ForceUpdateModal';
import SecurityModal from 'components/modal/splashModals/SecurityModal';
import TopModal from 'components/modal/topModal/TopModal';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import { securityModalState } from 'states/modalState';
import { sendReactNativeMessage } from './sendReactNativeMessage';
import useWebviewDeepLink from '@hooks/useWebviewDeepLink';
import { deviceIdState } from '@states/deviceIdState';
import { dateState } from '@states/DateState';
import { toStringByFormatting } from '@modules/helper';
import { compareVersionNumber } from '@utils/compareVersionNumbers';
import { isPurchaseLoadingState } from 'pages/apple/states';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import ModalsProvider from '@components/modal/defaultModal/ModalProvider';
import { batchRequestsOf } from '@utils/batchRequestsOf';

const useStyle = makeStyles(() =>
  createStyles({
    inoutMode: {
      position: 'relative',
      width: '100vw',
      maxWidth: 428,
      height: 'auto',
      minHeight: '100vh',
      margin: 'auto',
      background: '#fff',
    },
    background: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background: '#F3F4F5',
    },
    splash: {
      position: 'absolute',
      top: 0,
      zIndex: 10000,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      background: '#fff',
    },
  }),
);

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

export const App: React.FC = () => {
  const FORCE_VERSION = process.env.REACT_APP_FORCE_VERSION!;

  const classes = useStyle();
  const location = useLocation();
  const path = location.pathname;
  const isProcess = path.split('/')[-1] === 'token';
  const token = getCookie(ACCESS_TOKEN);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const setSpinner = useSetRecoilState(spinnerState);
  const setUpdatePopUpOpen = useSetRecoilState(indexPopUpState);
  const setPopupOpen = useSetRecoilState(updatePopUpState);
  const setDateState = useSetRecoilState(dateState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [appStatus, setAppStatus] = useRecoilState(appStatusState);
  const [isForce, setIsForce] = useRecoilState(forceState);
  const fcmReactToken = getCookie('fcmToken');
  const [isSecurity, setIsSecurity] = useRecoilState(securityModalState);
  const isBrowser = (window as any)?.ReactNativeWebView === undefined;
  const isBrowserAccessDenied = !!process.env.REACT_APP_WEBVIEW_ACCESS_DENIED;
  const { handlePushAlarmRedirect } = usePushAlarmRedirect();
  const { handleDeepLink } = useWebviewDeepLink();
  const setDeviceId = useSetRecoilState(deviceIdState);
  const isPurchaseLoading = useRecoilValue(isPurchaseLoadingState);
  const { refetch } = useUserInfoQuery(false, false);

  const postMessageListener = async (event: any) => {
    const path = window.location.hash;

    let postMessage;
    if (typeof event.data === 'string') {
      postMessage = JSON.parse(event.data);
    } else {
      postMessage = event.data;
    }

    if (window.location.hash === '#/login') {
      const { token, type } = postMessage;
      if (token) {
        navigate(`/token/${type}/${token}`);
      } else {
        setSpinner(true);
      }
    } else if (path === '#/' || path === '') {
      const { fcmToken, version } = postMessage;

      if (appStatus !== 'foreground') return;
      if (
        fcmToken !== 'null' &&
        fcmToken !== undefined &&
        fcmToken !== null &&
        fcmToken !== fcmReactToken
      ) {
        setCookieApp('fcmToken', fcmToken);
        const batchedSetFcmToken = batchRequestsOf(setFcmToken);
        await batchedSetFcmToken(fcmToken, deviceOs, 'null');
      } else {
        const batchedSetFcmToken = batchRequestsOf(setFcmToken);
        await batchedSetFcmToken(fcmReactToken || 'null', deviceOs, 'null');
      }
      if (version === undefined) return;
      const data = await getLastestVersion();
      if (data.version !== Number(version)) {
        setUpdatePopUpOpen(true);
        setCookieMonth('update', version);
      } else if (data.version === Number(version)) {
        deleteCookie('update');
      }
    } else if (
      window.location.hash === '#/myPage/setting/alarm' ||
      window.location.hash === '#/myPage/setting/customAlarm'
    ) {
      const { permissionState, fcmToken } = postMessage;
      if (permissionState === 'N') {
        await setFcmToken(!fcmToken ? 'null' : fcmToken, deviceOs, 'N');
        setPopupOpen(true);
      } else {
        setCookieApp('fcmToken', fcmToken);
        await setFcmToken(fcmToken, deviceOs, 'Y');
        setPopupOpen(false);
      }
    } else if (path.includes('/battle/chat')) {
      const { appState, type } = postMessage;
      if (type) {
        setAppStatus('foreground');
        return;
      }

      setAppStatus(appState);
    }
  };

  const sendForceUpdateMessage = () => {
    if ((window as any)?.ReactNativeWebView === undefined) {
      return;
    }

    (window as any)?.ReactNativeWebView?.postMessage('force-update-request');
  };

  useGoogleAnalytics();

  useEffect(() => {
    sendForceUpdateMessage();

    if (deviceOs === 'ios') {
      window.addEventListener('message', postMessageListener);
    } else {
      document.addEventListener('message', postMessageListener);
    }

    const token = getCookie(ACCESS_TOKEN);

    if (token) {
      if (
        path.split('/')[1] === 'KetoRedirect' ||
        path.split('/')[1] === 'welcome' ||
        path.split('/')[1] === 'dToken'
      ) {
        return;
      }
      (async () => {
        const { data } = await refetch();
        if (data) {
          setUserInfo(data);
          if (data.profile_check === 0) {
            navigate('/welcome');
          }
        }
      })();
    } else if (!isProcess && !token) {
      if (path.includes('privacy')) return;
      navigate('/login', { replace: true });
    }

    const key = setTimeout(() => {
      setIsLoading(true);
    }, 300);

    return () => {
      if (deviceOs === 'ios') {
        window.removeEventListener('message', postMessageListener);
      } else {
        document.removeEventListener('message', postMessageListener);
      }

      clearInterval(key);
    };
  }, []);

  useEffect(() => {
    if (!userInfo.member_idx) return;
    if (path.split('/')[1] === 'battle') return;
  }, [userInfo]);

  const refetchUserInfoQuery = async () => {
    const loginStatus = !!token;
    const { data } = await refetch();

    if (loginStatus && data) {
      sendReactNativeMessage({
        type: 'login-status',
        payload: JSON.stringify({ loginStatus, token, user: data }),
      });
    } else {
      sendReactNativeMessage({
        type: 'login-status',
        payload: JSON.stringify({
          loginStatus,
          token: 'not_login',
          user: { memberIdx: 'not_login' },
        }),
      });
    }
  };

  useEffect(() => {
    refetchUserInfoQuery();
  }, [token]);

  const androidBackHandlerPostMessageListener = (event: any) => {
    let message;

    if (typeof event.data === 'string') {
      message = JSON.parse(event.data);
    } else {
      message = event.data;
    }

    const { type } = message;

    if (type === 'back-button-press') {
      // 결제 진행 중인 경우 뒤로가기 막기

      if (isPurchaseLoading) return;
      if (window.location.hash.includes('roomEditor')) return;
      if (window.location.hash.includes('applicationForm')) return;
      if (window.location.hash.includes('certifiedForm')) return;
      if (window.location.hash.includes('/survey/progress')) return;
      if (window.location.hash === '#/' || window.location.hash === '') return;

      navigate(-1);
    }
  };

  useEffect(() => {
    if (deviceOs === 'ios') {
      window.addEventListener('message', androidBackHandlerPostMessageListener);
      return () => {
        window.removeEventListener('message', androidBackHandlerPostMessageListener);
      };
    }

    document.addEventListener('message', androidBackHandlerPostMessageListener);
    return () => {
      document.removeEventListener('message', androidBackHandlerPostMessageListener);
    };
  }, [isPurchaseLoading]);

  useEffect(() => {
    sendReactNativeMessage({
      type: 'get-security-enabled',
    });
    sendReactNativeMessage({
      type: 'get-device-id',
    });
  }, []);

  usePostMessageReceiver((event: any) => {
    let message;

    if (typeof event.data === 'string') {
      message = JSON.parse(event.data);
    } else {
      message = event.data;
    }

    const { type, data } = message;

    const receiveHandler: {
      [key: string]: () => void;
    } = {
      'device-id': () => {
        setDeviceId(data);
      },
      'force-update-response': () => {
        const versionCheck = compareVersionNumber(FORCE_VERSION, data);
        if (versionCheck) return;
        setIsForce(true);
      },
      'security-enabled': () => {
        const { isLock } = data;
        if (isLock) {
          setIsSecurity(true);
        }
      },
      logout: () => {
        deleteCookie(ACCESS_TOKEN);
        deleteCookie('fcmToken');
      },
      'webview-deep-link': () => {
        setDateState(toStringByFormatting(new Date()));
        handleDeepLink(data);
      },
      push_signal: () => {
        if (data.head === 'foreground') return;
        handlePushAlarmRedirect(data);
      },
      default: () => {
        console.log('NOT EXIST POSTMESSAGE TYPE ', type);
      },
    };

    (receiveHandler[type] || receiveHandler['default'])();
  });

  // eslint-disable-next-line react/jsx-no-undef
  // return <>{isLoading && <EmergencyModal />}</>;

  /**
   * 2023/01/13(금) 브라우저를 통한 해킹 의심 사건으로
   * 브라우저를 통한 루트는 모두 disable 처리
   */
  if (isBrowser && isBrowserAccessDenied) {
    return <></>;
  }

  if (isForce) {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <Grid className={classes.background} />
        <Grid className={classes.inoutMode}>
          <ForceRouter />
          <Grid className={classes.splash}>{isLoading && <ForceUpdateModal />}</Grid>
        </Grid>
      </StylesProvider>
    );
  }

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Grid className={classes.background} />
      <Grid className={classes.inoutMode}>
        <TopModal />
        <SplashModal />
        <ToastModal />
        <TimeoutModal />
        {isSecurity && (
          <SplashModalContainer>
            <SecurityModal
              onClose={() => {
                setIsSecurity(false);
              }}
            />
          </SplashModalContainer>
        )}
        <ModalsProvider>
          <Router />
        </ModalsProvider>
      </Grid>
    </StylesProvider>
  );
};

export default App;
