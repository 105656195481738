export const countSplitText = (str: string) => {
  // 100개, 200개 등 숫자+개 의 경우엔 span 태그로 감싸기
  return str
    .split(/(\d*[개])/)
    .map((text) => (/(\d*[개])/.test(text) ? `<span>${text}</span>` : text))
    .join('');
};

export const numberSplitText = (str: string) => {
  return str
    .split(/(\d+)/)
    .map((text) => (/(\d+)/.test(text) ? `<span>${text}</span>` : text))
    .join('');
};
