import { AdContent, AdKind } from '@models/ad';
import useGetAdContents from './queries/useGetAdContents';
import { useMutation, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { countClickBanner, countViewBanner } from '@apis/adApi';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  kind: AdKind;
  options?: UseQueryOptions<AdContent[], AxiosError, AdContent[]>;
}

const useAdBannerController = ({ kind, options }: Props) => {
  const navigate = useNavigate();
  const viewBannerIdx = useRef<Map<number, number>>(new Map());
  const { data: bannerData, isFetching } = useGetAdContents(kind, options);
  const { mutate: postAdBannerView } = useMutation((ad_idx: number) => countViewBanner(ad_idx));
  const { mutate: postAdBannerClick } = useMutation((ad_idx: number) => countClickBanner(ad_idx));

  const onClickAdBanner = useCallback(
    (link: string, ad_idx: number) => {
      const isExternal = link.includes('http');
      postAdBannerClick(ad_idx);

      if (isExternal) {
        window.location.href = link;
      } else {
        navigate(`${link}`);
      }
    },
    [navigate, postAdBannerClick],
  );

  const onViewAdBanner = useCallback(
    (swiperIndex: number) => {
      if (!bannerData || viewBannerIdx.current.has(swiperIndex)) return;

      viewBannerIdx.current.set(swiperIndex, bannerData[swiperIndex]?.ad_idx);

      const viewedBannerIdx = viewBannerIdx.current.get(swiperIndex);
      if (viewedBannerIdx) {
        postAdBannerView(viewedBannerIdx);
      }
    },
    [bannerData, postAdBannerView],
  );

  return {
    isFetching,
    bannerData,
    onClickAdBanner,
    onViewAdBanner,
  };
};

export default useAdBannerController;
