import usePostMessageReceiver from '@hooks/usePostMessageReceiver';
import useModal from '@hooks/useModal';
import { useEffect, useRef } from 'react';

interface Props {
  onOpenModal?: () => void;
}

const useModalBackPressHandling = ({ onOpenModal }: Props) => {
  const { hasModal, closeLastModal } = useModal();
  const hasModalRef = useRef(false);

  usePostMessageReceiver((event: any) => {
    const { type } = JSON.parse(event.data);

    if (type === 'back-button-press') {
      if (hasModalRef.current) {
        closeLastModal();
      } else {
        if (onOpenModal) {
          onOpenModal();
        }
      }
    }
  });

  useEffect(() => {
    hasModalRef.current = hasModal;
  }, [hasModal]);
};

export default useModalBackPressHandling;
