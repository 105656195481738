import Portal from '@components/portal';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import { getToday } from '@utils/fotmatDate';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const CommunityNavigator = () => {
  const location = useLocation();
  const activeTab = location.pathname.includes('/anonymity') ? 'bamboo' : 'feed';
  const navigate = useNavigate();
  const { sendAdBrixData } = useAdBrix();

  return (
    <Portal>
      <NavigationContainer>
        <TabContainer>
          <ActiveTabBackground activeTab={activeTab} />
          <Tab
            active={activeTab === 'bamboo'}
            onClick={() => {
              if (activeTab === 'bamboo') return;
              sendAdBrixData('customEvent', {
                eventKey: 'click_toggle_forest',
              });
              navigate('/communication/anonymity/recommend');
              window.scrollTo(0, 0);
            }}
            width="80px"
          >
            대나무숲
          </Tab>

          <Tab
            active={activeTab === 'feed'}
            width="54px"
            onClick={() => {
              if (activeTab === 'feed') return;
              sendAdBrixData('customEvent', {
                eventKey: 'click_toggle_feed',
              });
              navigate(
                `/communication/ketogram/recommend?tab=실시간&startDate=${getToday()}&endDate=${getToday()}`,
              );
              window.scrollTo(0, 0);
            }}
          >
            피드
          </Tab>
        </TabContainer>
      </NavigationContainer>
    </Portal>
  );
};

export default CommunityNavigator;

const NavigationContainer = styled.div`
  position: fixed;
  left: 50%;
  bottom: 84px;
  width: 142px;
  height: 44px;
  z-index: 10;
  transform: translateX(-50%);
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  width: 142px;
  height: 44px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 25px;
  padding: 4px;
  position: relative;
`;

const Tab = styled.button<{ active: boolean; width: string }>`
  height: 36px;
  text-align: center;
  border: none;
  width: ${({ width }) => width};
  cursor: pointer;
  color: white;
  position: relative;
  z-index: 2;
  font-family: 'Noto Sans KR';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1px;
`;

const ActiveTabBackground = styled.div<{ activeTab: string }>`
  position: absolute;
  will-change: left, width, transition;
  width: ${({ activeTab }) => (activeTab === 'bamboo' ? '80px' : '54px')};
  height: 36px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 120px;
  transition: all 0.45s ease-in-out;
  top: 4px;
  bottom: 4px;
  left: ${({ activeTab }) => (activeTab === 'bamboo' ? '4px' : '84px')};
`;
