import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import SlimPopup from '@components/elements/Popup/SlimPopup';

const ParticipationClosedFallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();

  const handleConfirm = () => {
    queryClient.invalidateQueries({ queryKey: ['participationList'] });

    if (location.pathname.includes('/survey/progress')) {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  return (
    <SlimPopup
      headText={`정원이 마감되어\n더 이상 참여할 수 없어요`}
      buttonText1="확인"
      onClick={handleConfirm}
      onClose={handleConfirm}
      dimmedColor="#757575"
    />
  );
};

export default ParticipationClosedFallback;
