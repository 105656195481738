import { createGlobalStyle } from 'styled-components';
import font from './base/_font';
import cx from 'classnames';
export { cx };

const GlobalStyle = createGlobalStyle`
  ${font}
`;

export default GlobalStyle;
