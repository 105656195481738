import { lazy, Suspense } from 'react';
import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { Route, Routes } from 'react-router-dom';
import { ApplePath } from '@constants/internalPath';
import { ErrorBoundary } from 'react-error-boundary';
import ParticipationClosedFallback from '@pages/apple/ad/participation/components/ParticipationClosedFallback';
import { AxiosError } from 'axios';

const ApplePage = lazy(() => import('@pages/apple'));
const DailyMission = lazy(() => import('@pages/apple/dailyMission'));
const StoreDetail = lazy(() => import('pages/apple/store/storeDetail'));
const MyRoomStoreDetail = lazy(() => import('@pages/apple/store/myRoomStoreDetail'));
const ThemeWishlist = lazy(() => import('@pages/apple/store/wishlist'));
const CertifiedAdList = lazy(() => import('@pages/apple/ad/certified/CertifiedAdList'));
const CertifiedAdDetail = lazy(() => import('@pages/apple/ad/certified/CertifiedDetail/index'));
const ApplicationForm = lazy(() => import('@pages/apple/ad/certified/ApplicationForm'));
const CertifiedForm = lazy(() => import('@pages/apple/ad/certified/CertifiedForm'));
const Store = lazy(() => import('@pages/apple/store'));
const MySticker = lazy(() => import('@pages/apple/sticker'));
const MyApple = lazy(() => import('@pages/apple/myApple'));
const ParticipationAdList = lazy(() => import('@pages/apple/ad/participation/ParticipationAdList'));
const ParticipationAdDetail = lazy(
  () => import('@pages/apple/ad/participation/ParticipationAdDetail'),
);
const SurveyProgress = lazy(
  () => import('@pages/apple/ad/participation/components/survey/SurveyProgress'),
);

const AppleRouter = () => {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path={`${ApplePath.MAIN}/:menu`} element={<ApplePage />} />
        <Route path={ApplePath.DAILY_MISSION} element={<DailyMission />} />
        <Route path={`${ApplePath.STORE}/:tab`} element={<Store />} />
        <Route path={`${ApplePath.STICKER}/:tab?`} element={<MySticker />} />
        <Route path={`${ApplePath.MY_APPLE}/:tab?`} element={<MyApple />} />

        <Route path={ApplePath.STORE_THEME_DETAIL} element={<StoreDetail />} />
        <Route path={ApplePath.STORE_MY_ROOM_DETAIL} element={<MyRoomStoreDetail />} />
        <Route path={ApplePath.THEME_WISHLIST} element={<ThemeWishlist />} />

        {/* 광고 */}
        <Route path={ApplePath.AD_CERTIFIED_LIST} element={<CertifiedAdList />} />
        <Route path={ApplePath.AD_CERTIFIED_DETAIL} element={<CertifiedAdDetail />} />
        <Route path={ApplePath.AD_CERTIFIED_APPLICATION_FORM} element={<ApplicationForm />} />
        <Route path={ApplePath.AD_CERTIFIED_FORM} element={<CertifiedForm />} />
        <Route path={ApplePath.AD_PARTICIPATION_LIST} element={<ParticipationAdList />} />
        <Route path={ApplePath.AD_PARTICIPATION_DETAIL} element={<ParticipationAdDetail />} />
        <Route
          path={ApplePath.AD_PARTICIPATION_DETAIL_SURVEY_PROGRESS}
          element={
            <ErrorBoundary
              FallbackComponent={ParticipationClosedFallback}
              onError={(error) => {
                if (!(error instanceof AxiosError) || error.response?.status !== 403) {
                  throw error;
                }
              }}
            >
              <SurveyProgress />
            </ErrorBoundary>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppleRouter;
