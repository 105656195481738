import { getAdContents } from '@apis/adApi';
import { AdContent, AdKind } from '@models/ad';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

const useGetAdContents = (
  kind: AdKind,
  options?: UseQueryOptions<AdContent[], AxiosError, AdContent[]>,
) => {
  return useQuery<AdContent[], AxiosError, AdContent[]>(
    ['adContents', kind],
    () => getAdContents(kind),
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetAdContents;
