/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
const noop = () => {};

type CallbackFunctionType = (...args: any[]) => any;

export function batchRequestsOf<F extends CallbackFunctionType>(func: F) {
  const promiseByKey = new Map<string, Promise<ReturnType<F>>>();

  return function (...args: Parameters<F>) {
    const key = JSON.stringify(args);

    if (promiseByKey.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return promiseByKey.get(key)!;
    } else {
      const promise = func(...args);
      promise.then(() => {
        promiseByKey.delete(key);
      }, noop);
      promiseByKey.set(key, promise);

      return promise;
    }
  } as F;
}

// 토스 - https://github.com/toss/slash/blob/main/packages/common/utils/src/batchRequestsOf.ts
