import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import Portal from '@components/portal';
import { COLORS } from '@styles/constants/_colors';
import { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import RewardsImg from '@assets/images/rewards/rewards.png';
import { countSplitText } from '@utils/splitHelper';

interface Props {
  onClose?: () => void;
  completeMessage: string;
  title: string;
  img?: string;
  bottomSubText?: string;
}

const RewardsModal = ({
  onClose,
  completeMessage,
  title,
  img = RewardsImg,
  bottomSubText,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  return (
    <Portal>
      <DimmedWrapper level={2600} onClose={onClose}>
        <ModalField isOpen={isOpen}>
          <ContentsField>
            <CompleteMessage>{completeMessage}</CompleteMessage>
            <Title
              dangerouslySetInnerHTML={{
                __html: countSplitText(title),
              }}
            />
            <Img src={img} />
          </ContentsField>
          <Button onClick={onClose}>확인했어요</Button>
          <BottomSubText>{bottomSubText}</BottomSubText>
        </ModalField>
      </DimmedWrapper>
    </Portal>
  );
};

export default RewardsModal;

const ModalField = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 50%;
  top: ${({ isOpen }) => (isOpen ? '50%' : '100%')};
  transform: ${({ isOpen }) => (isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, 100%)')};
  background-color: ${COLORS.WHITE};
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 295px;
  overflow: hidden;
  z-index: 2600;
  transition: all 200ms ease;
  padding: 38px 30px 31px;
`;

const ContentsField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  margin-bottom: 20px;
`;

const CompleteMessage = styled.p`
  font-family: 'Cafe24 Ssurround';
  font-size: 15px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: ${COLORS.BLACK};
`;

const Img = styled.img`
  width: 100%;
  height: 160px;
  object-fit: contain;
`;

const Title = styled.p`
  font-family: 'Cafe24 Ssurround';
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: ${COLORS.BLACK};
  margin-bottom: 3px;

  span {
    color: ${COLORS.RED};
  }
`;

const Button = styled.button`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 120px;
  background-color: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  font-family: 'Cafe24 Ssurround';
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  margin-bottom: 19px;
`;

const BottomSubText = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: ${COLORS.PRIMITIVES_GRAY_600};
`;
