import { getCurrentQuarter } from '@utils/timeUtil';
import dayjs from 'dayjs';

const useGroupBattleSeasonEndCheck = () => {
  const isSeasonEnd = () => {
    const now = dayjs().tz('Asia/Seoul');
    const seasonStartDate = '2025-01-01';

    const currentQuarter = getCurrentQuarter(seasonStartDate);
    const currentDayjs = dayjs(seasonStartDate).tz('Asia/Seoul');
    // 이번 분기의 다음 달
    const nextQuarterFirstMonth = (currentQuarter % 4) * 3 + 1;
    // 다음 분기가 1분기면 연도 증가
    const nextQuarterFirstYear =
      currentQuarter === 4 ? currentDayjs.year() + 1 : currentDayjs.year();
    // 이번 분기의 다음 달 첫 날
    const nextQuarterFirstDay = dayjs(seasonStartDate)
      .tz('Asia/Seoul')
      .year(nextQuarterFirstYear)
      .month(nextQuarterFirstMonth - 1)
      .startOf('month');
    // 매 분기의 다음 달 첫 날 00:00 ~ 06:00 사이에는 시즌 종료 화면 렌더링
    const startOfNextQuarterFirstDay = nextQuarterFirstDay.startOf('day');
    const endOfNextQuarterFirstDay = nextQuarterFirstDay.startOf('day').add(6, 'hour');
    const isInNextQuarterFirstDayEarlyHours =
      now.isAfter(startOfNextQuarterFirstDay) && now.isBefore(endOfNextQuarterFirstDay);

    return isInNextQuarterFirstDayEarlyHours;
  };
  return { isSeasonEnd };
};

export default useGroupBattleSeasonEndCheck;
