import { createStyles, Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import Skeleton from '../Skeleton';
import styled from 'styled-components';
import ArrowBack from '@assets/images/icon/GNB/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { deviceOs } from '@modules/platformCheck';

interface Props {
  backgroundColor?: string;
  arrowColor?: string;
  text?: string | ReactNode;
  textColor?: string;
  onClick?(): void;
  loading?: boolean;
  notFixed?: boolean;
  bottomLine?: boolean;
  isDot?: boolean;
  onDotClick?: MouseEventHandler;
  optionButton?: ReactNode;
}

export default function BackArrowGnb({
  children,
  backgroundColor = '#fff',
  arrowColor = '#000',
  text,
  textColor = '#000',
  onClick,
  loading = false,
  notFixed = false,
  bottomLine,
  isDot,
  onDotClick,
  optionButton,
}: PropsWithChildren<Props>) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBackClick = () => {
    onClick ? onClick() : navigate(-1);
  };

  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      className={classNames([
        classes.container,
        notFixed && classes.notFixed,
        bottomLine && classes.bottomLine,
      ])}
      style={{
        background: backgroundColor,
      }}
    >
      <BackButtonBox
        onClick={handleBackClick}
        onTouchEnd={() => {
          if (deviceOs === 'android') return;
          handleBackClick();
        }}
      >
        <ArrowBack color={arrowColor} />
      </BackButtonBox>
      {!!text &&
        (loading ? (
          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            className={classes.skeletonHeader}
          >
            <Skeleton width={100} height={15} />
          </Grid>
        ) : (
          <p className={classes.boardHeader} style={{ color: textColor }}>
            {text}
          </p>
        ))}
      {children}
      {optionButton && optionButton}
      {isDot && (
        <Grid
          className={classes.dots}
          container
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          onClick={onDotClick}
        >
          <Grid />
          <Grid />
          <Grid />
        </Grid>
      )}
    </Grid>
  );
}

const BackButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  height: 50px;
`;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: 428,
      position: 'fixed',
      top: 0,
      zIndex: 1000,
      height: 50,
      padding: '0 20px',
    },
    bottomLine: {
      borderBottom: '0.5px solid #ECF0F2',
    },
    notFixed: {
      position: 'relative',
    },
    boardHeader: {
      position: 'absolute',
      top: 17,
      left: 50,
      width: 'calc(100% - 100px)',
      height: 14,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: -0.54,
      textAlign: 'center',
    },
    skeletonHeader: {
      position: 'absolute',
      left: 0,
      top: 17,
      height: 15,
    },
    dots: {
      width: 15,
      zIndex: 10,
      '& div': {
        width: 4,
        height: 4,
        borderRadius: '50%',
        background: '#CECED0',
      },
    },
  }),
);
