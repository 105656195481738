import { exerciseThemeIndex } from 'pages/exercise/states';
import { myChangeTabIndexState, myEatTabIndexState } from 'pages/main/states';
import { useEffect, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { mealDetailPeriodIndexState } from 'states/mainPageState';
import styled from 'styled-components';
import LEVEL from '@styles/constants/_levels';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import { StatsActiveIcon, StatsIcon } from '@assets/svgs/illust';
import { AlarmGrayIcon } from '@assets/svgs';
import { handleOverValue } from '@utils/formatNumber';
import useGetMyNewsCountQuery from '../hooks/useGetMyNewsCountQuery';
import { CalendarIcon } from '@assets/svgs/main';
import useAdBrix from '@hooks/adBrix/useAdBrix';

const tabList = [
  {
    index: 0,
    name: '기록',
    to: '',
  },
  {
    index: 1,
    name: '단식',
    to: 'fasting/timer',
  },
];

export default function MainTabHeader() {
  const { sendAdBrixData } = useAdBrix();
  const navigate = useNavigate();

  const { data: myNewsCountData } = useGetMyNewsCountQuery();

  const [, setMyChangeTabIndex] = useRecoilState(myChangeTabIndexState);
  const [, setMyEatTabIndex] = useRecoilState(myEatTabIndexState);
  const [, SetExerciseThemeIndex] = useRecoilState(exerciseThemeIndex);
  const [, setMealDetailPeriodIndex] = useRecoilState(mealDetailPeriodIndexState);

  const hasNotificationCount = useMemo(() => {
    if (!myNewsCountData?.newsCount) return false;

    return myNewsCountData?.newsCount !== 0;
  }, [myNewsCountData?.newsCount]);

  const handleCalendarClick = () => {
    sendAdBrixData('customEvent', {
      eventKey: `click_calender`,
    });
    navigate('/calenderDiary');
  };

  const handleStatisticsClick = () => {
    navigate('/statistics');
  };

  const handleNotificationClick = () => {
    navigate('/myPage/news?tab=인아웃 공지');
  };

  const handleClickEvent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (event.currentTarget.className.includes('active')) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (location.hash !== '#/') {
      setMyChangeTabIndex(0);
      setMyEatTabIndex(0);
      SetExerciseThemeIndex(0);
      setMealDetailPeriodIndex('day');
    }
  }, []);

  return (
    <Wrapper>
      <MainNavField>
        <NavUnit
          $selected={location.hash === '' || location.hash === '#/'}
          to={`/${tabList[0].to}`}
          onClick={handleClickEvent}
        >
          {tabList[0].name}
        </NavUnit>
        <NavUnit
          $selected={
            location.hash.includes(tabList[1].to) || location.hash.includes('/fasting/record')
          }
          to={`/${tabList[1].to}`}
          onClick={handleClickEvent}
        >
          {tabList[1].name}
        </NavUnit>
      </MainNavField>
      <SubNavField>
        <CalendarIcon onClick={handleCalendarClick} />
        {location.hash.includes('statistics') ? (
          <StatsActiveIcon />
        ) : (
          <StatsIcon onClick={handleStatisticsClick} />
        )}
        <NotiBox>
          <AlarmGrayIcon onClick={handleNotificationClick} />
          {hasNotificationCount && (
            <NewsCount>
              <span>{handleOverValue(myNewsCountData?.newsCount ?? 0, 99)}</span>
            </NewsCount>
          )}
        </NotiBox>
      </SubNavField>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: ${LEVEL.COMMUNICATIONS_HEADER};
  background-color: ${FOUNDATION_COLOR.BASIC_WHITE};

  height: 68px;
  padding: 30px 20px 10px;
`;

const MainNavField = styled.div`
  display: flex;
  gap: 14px;
`;

const NavUnit = styled(NavLink)<{ $selected: boolean }>`
  color: ${({ $selected }) =>
    $selected ? FOUNDATION_COLOR.BASIC_BLACK : COLORS.PRIMITIVES_GRAY_350};
  position: relative;
  cursor: pointer;

  font-size: 20px;
  letter-spacing: -0.5px;
  font-family: 'Noto Sans KR';
  font-weight: 700;

  :hover {
    color: ${({ $selected }) =>
      $selected ? FOUNDATION_COLOR.BASIC_BLACK : COLORS.PRIMITIVES_GRAY_350};
  }
`;

const SubNavField = styled.div`
  display: flex;
  gap: 20px;

  svg {
    cursor: pointer;
  }
`;

const NotiBox = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`;

const NewsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 18px;
  height: 18px;
  top: -6px;
  right: -8px;
  border-radius: 50%;
  background-color: ${COLORS.RED};

  span {
    color: ${COLORS.WHITE};
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 120%;
  }
`;
